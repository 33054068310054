.projects-main {
  width: 100%;
  text-align: center;
}

.basic-projects {
  margin-left: 5%;
  margin-right: 5%;
}

.projects-heading-div {
  display: flex;
}

.projects-heading-div > * {
  flex: 1;
}

.projects-heading-text-div-new {
  text-align: center;
}

.projects-heading-text-new {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin: 3%;
}

.projects-header-detail-text {
  font-size: 15px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.projects-header-detail-text > * {
  flex: 1;
  flex-shrink: 0;
}

.description {
  flex-grow: 2;
}
ul {
  list-style: none;
}
a {
  color: blue;
}

@media (max-width: 1380px) {
  .projects-heading-text-new {
    font-size: 50px;
  }
  .projects-heading-sub-text {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .projects-heading-text-new {
    font-size: 30px;
    margin-top: 20px;
  }

  .projects-heading-sub-text {
    font-size: 20px;
  }

  .projects-heading-div {
    flex-direction: column;
  }

  .projects-header-detail-text {
    font-size: 16px;
    margin-right: 0px;
    margin-left: 0px;
    line-height: normal;
    text-align: center;
  }
}
