.dev-icons {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: left;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-family: "Google Sans Regular";
}

.software-skill-inline > i {
  color: #868e96;
}

.software-skill-inline > i:hover {
  color: #645beb;
}

@media (max-width: 1380px) {
  .dev-icons {
    font-size: 23px;
  }

  .software-skill-inline {
    margin-right: 8px;
  }
}
@media (max-width: 768px) {
  .dev-icons {
    font-size: 18px;
  }
  .software-skill-inline {
    margin-right: 6px;
  }
}

@media (min-width: 1380px) {
  .dev-icons {
    font-size: 35px;
  }
  .software-skill-inline {
    margin-right: 10px;
  }
}
