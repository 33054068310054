.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}
.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.greeting-main {
  width: 90%;

  margin: 0px auto;
}

.greeting-logo-title {
  display: inline-block;
}

.greeting-main {
  display: flex;
}

.greeting-main > * {
  flex: 2;
  margin-bottom: 30px;
}
.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

h1.greeting-text {
  display: inline-block;
  line-height: 0.8;
  margin-top: 4%;
}

.greeting-text {
  margin-top: 80px;
  font-size: 70px;
  line-height: 1.1;
  font-family: "Google Sans Bold";
}

.greeting-text-p {
  font-family: "Google Sans Medium";
  margin-top: 20px;
  display: flex;
  max-width: inherit !important;
}

.greeting-image-div > * {
  max-width: 100%;
  height: auto;
  margin-left: 10%;
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }

  h1.greeting-text {
    font-size: 5vw;
  }

  .greeting-text-p {
    /*font-size: 13px;*/
  }
}
@media (max-width: 768px) {
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    margin-top: 0px;
    text-align: center;
  }
  h1.greeting-text {
    font-size: 10vw;
  }
  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }
  .greeting-text-p {
    /*font-size: 11px;*/
    margin-right: 0px;
    line-height: normal;
    text-align: left;
  }
  .greeting-main {
    display: block;
  }

  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

/*
@media (min-width: 1380px) {
  .greeting-text-p {
    font-size: 17px;
  }
}

@media  (min-width: 50px)   {  .greeting-text-p  { font-size:0.05em;  } }
@media  (min-width: 100px)  {  .greeting-text-p  { font-size:0.1em;  } }
@media  (min-width: 200px)  {  .greeting-text-p  { font-size:0.2em;  } }
@media  (min-width: 300px)  {  .greeting-text-p  { font-size:0.3em;  } }
@media  (min-width: 400px)  {  .greeting-text-p  { font-size:0.4em;  } }
@media  (min-width: 500px)  {  .greeting-text-p  { font-size:0.5em;  } }
@media  (min-width: 600px)  {  .greeting-text-p  { font-size:0.6em;  } }
@media  (min-width: 700px)  {  .greeting-text-p  { font-size:0.7em;  } }
@media  (min-width: 800px)  {  .greeting-text-p  { font-size:0.8em;  } }
@media  (min-width: 900px)  {  .greeting-text-p  { font-size:0.9em;  } }
@media  (min-width: 1000px) {  .greeting-text-p  { font-size:1.0em;  } }
@media  (min-width: 1100px) {  .greeting-text-p  { font-size:1.1em;  } }
@media  (min-width: 1200px) {  .greeting-text-p  { font-size:1.2em;  } }
@media  (min-width: 1300px) {  .greeting-text-p  { font-size:1.3em;  } }
@media  (min-width: 1400px) {  .greeting-text-p  { font-size:1.4em;  } }
@media  (min-width: 1500px) {  .greeting-text-p  { font-size:1.5em;  } }
@media  (min-width: 1500px) {  .greeting-text-p  { font-size:1.6em;  } }
@media  (min-width: 1600px) {  .greeting-text-p  { font-size:1.7em;  } }
@media  (min-width: 1700px) {  .greeting-text-p  { font-size:1.8em;  } }

*/
