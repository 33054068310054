.footer-text {
  text-align: center;
  /* color: #868e96; */
  font-weight: bold;
  font-family: "Google Sans Regular";
  display: inline-block;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 40px;
}

.footer-div {
  margin-top: 2rem;
}

.footer-line {
  margin: 38px 64px;
}

.left {
  float: left;
}

.right {
  float: right;
  text-decoration: none;
}

.spacing {
  margin-left: 20px;
}

a {
  text-decoration: none;
  color: #868e96 !important;
}

.footer-nav {
  margin-top: 20%;
}

.footer-logo {
  text-align: left;
}

/* Media Query */
@media (max-width: 1380px) {
}
@media (max-width: 900px) {
  .footer-logo {
    text-align: center;
  }
  .right {
    width: 100%;
    float: none;
  }
  .left {
    float: none;
  }
  .footer-nav {
    margin-top: auto;
  }
  .footer-text {
    text-align: center;
    /* color: #868e96; */
    font-weight: bold;
    font-family: "Google Sans Regular";
    display: block;
    padding: 12px 0px;
  }
}
